.login-container {
    max-width: 350px;
    margin: auto;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f4f5f7;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.logo {
    font-size: 48px;
    margin-bottom: 20px;
    transition: transform 0.2s ease-in-out, color 0.2s ease-in-out;
}

.logo.success {
    color: #52c41a; /* Зеленый цвет для галочки */
    animation: scale-up 0.5s ease-in-out forwards;
}

@keyframes scale-up {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.login-form {
    width: 100%;
}

.login-form .ant-input-affix-wrapper {
    padding: 10px;
    font-size: 16px;
}

.login-form .ant-btn {
    background-color: #1890ff;
    border-color: #1890ff;
    font-size: 16px;
    padding: 10px;
}

.login-form .ant-btn:hover {
    background-color: #40a9ff;
    border-color: #40a9ff;
}
